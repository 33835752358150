import { IAnswers, answersOptions } from "Context/answersContext";
import { IDomainContext } from "Context/domainContext";
import { convertPoundToKg } from "helpers/general";

export const mapToML = (answers: IAnswers, domain: IDomainContext) => {
    return {
        ...mapOptions(answers, domain)
    }
}

const mapOptions = (answers: IAnswers, domain: IDomainContext) => {
    return {
        born_stage: (() => {
            switch (answers.infantAge) {
                case 'no_born': return 'not_born';
                case 'just_born': return 'just_born';
                case 'pre_solids': return 'pre_eating_solids';
                case 'eat_solids': return 'eating_solids';
            }
        })(),
        birth_place: (() => {
            if(answers.infantAge === 'no_born') {
                switch (answers.childEnvironment) {
                    case 'urban': return 'city';
                    case 'suburb': return 'suburbs';
                    case 'rural': return 'countryside';
                }
            }
            switch (answers.placeOfBirth) {
                case 'city': return 'city';
                case 'suburbs': return 'suburbs';
                case 'country_side': return 'countryside';
            }
        })(),
        mother_atopic_cond: mapConditions(answers.motherConditions),
        mother_atopic_cond_pregnancy: answers.conditionsDuringPregnant === 'yes',
        mother_antibiotics_pregnancy: (() => {
            switch (answers.antibiotics) {
                case 'zero': return 0;
                case 'one': return 1;
                case 'two': return 2;
                case 'three': return 3;
                case 'three_plus': return 4;
            }
        })(),
        father_atopic_cond: mapConditions(answers.fatherConditions),
        smoking: answers.isSmoking === 'yes',
        siblings_num: +answers.siblingsCount,
        siblings_atopic_cond: mapConditions(answers.siblingsConditions),
        siblings_with_atopic_num: +answers.siblingsConditionsCount,
        birth_date: (() => {
            if(answers.infantAge === 'no_born') {
                return toDateFormat(answers.infantDueDateOfBirth)
            }
            return toDateFormat(answers.infantDateOfBirth)
        })(),
        gender: (() => {
            if(answers.gender) {
                switch (answers.gender) {
                    case 'female': return 'female';
                    case 'male': return 'male';
                    default: return 'unknown';
                }
            }
            if(answers.genderKnown) {
                switch (answers.genderKnown) {
                    case 'girl': return 'female';
                    case 'boy': return 'male';
                    default: return 'unknown';
                }
            }
        })(),
        weeks_pregnant_when_born: +answers.weeksPregnant,
        has_food_allergies: answers.infantHasAllergies === 'yes',
        born_by_caesarian: answers.isCesarian === 'yes',
        birth_weight: domain.weight === 'pounds' ? convertPoundToKg(+answers.weightPound) : +answers.weightKg,
        breastfed: answers.isBreastFeeding === 'yes',
        atopic_signs: answers.eczemaSigns === 'yes',
        atopic_diagnose: answers.eczemaSignsDiagnose === 'yes',
        systemic_antibiotics_prescribed: answers.eczemaSystematicAntibiotics === 'yes',
        topical_antibiotics_prescribed: answers.eczemaTopicalAntibiotics === 'yes'
    }
}

const mapConditions = (list: typeof answersOptions.conditions[number][]) => {
    return list.map((cond) => {
        switch (cond) {
            case 'asthma': return 'asthma';
            case 'atopic': return 'atopic_dermatitis';
            case 'food_allergy': return 'food_allergy';
            case 'hay_fever': return 'hay_fever';
        }
    }).filter(Boolean)
}

const toDateFormat = (dateISO?: Date | ''): string => {
    if (!dateISO) { return '' }

    const date = new Date(dateISO);

    const YYYY = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    const MM = month < 10 ? '0' + month : month;
    const DD = day < 10 ? '0' + day : day;

    return `${YYYY}-${MM}-${DD}`
}