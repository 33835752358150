import { IAnswers } from "Context/answersContext";
import { IFlowItem } from "Flow/types/flow.types";
import { createContext, useState } from "react";

export const observerContext = createContext<IContext>({
    getItem: () => ({
        elem: [],
        field: 'email'
    }),
    shout: () => {}
});

const Provider = observerContext.Provider;


type TState = Record<keyof IAnswers, IFlowItem>;

export const ObserverProvider = ({ children }: IProps) => {
    const [data, setData] = useState<TState | {}>({});

    const shout = (field: keyof IAnswers, item: IFlowItem) => {
        setData({
            ...data,
            [field]: item
        })
    }

    const getItem = (field: keyof IAnswers):  IFlowItem => {
        return (data as TState)[field] || {};
    }   

    const value = {
        getItem,
        shout
    }
    return (
        <Provider value={value}>
            {children}
        </Provider>
    )
}

interface IContext {
    getItem: (field: keyof IAnswers) => IFlowItem;
    shout: (field: keyof IAnswers, item: IFlowItem) => void;    
}

interface IProps {
    children: Elem
}

