import { answersContext } from "Context/answersContext";
import { flowSteps } from "Flow/Flow";
import { IFlowItem, THeader, TStep, TStepViews, TSteps } from "Flow/types/flow.types";
import { observer } from "Utils/observer";
import { createContext, useContext, useEffect, useState } from "react";

export const stepsContext = createContext<IContext>({
    handleNext: () => { },
    handleBack: () => { },
    handleStart: () => { },
    next: [
        {
            field: 'firstName',
            title: '',
            elem: []
        }
    ],
    header: '',
    per: 1,
    hasBack: false,
    stepIndex: 0,
    clearCache: () => { },
    initSteps: () => { }
});

const Provider = stepsContext.Provider;

const LOCAL_STEP = 'CURRENT_STEP';

const getStep = (): number => {
    const step = localStorage.getItem(LOCAL_STEP);
    if (step) {
        return +step;
    }
    return 0;
}

const setStep = (value: number) => {
    localStorage.setItem(LOCAL_STEP, value.toString());
}

const IS_COMPLETE = 'IS_COMPLETE';

export const getIsComplete = (): boolean => {
    const exist = localStorage.getItem(IS_COMPLETE);
    return !!exist;
}

export const setIsComplete = (flag: boolean) => {
    localStorage.setItem(IS_COMPLETE, flag.toString());
}


export const StepsProvider = ({ children }: IProps) => {
    const { answers, clearCache: clearCacheAnswers } = useContext(answersContext);
    const [stepIndex, setStepIndex] = useState(getStep());

    useEffect(() => {
        setStep(stepIndex);
    }, [stepIndex])

    const clearCache = () => {
        clearCacheAnswers();
        localStorage.removeItem(LOCAL_STEP);
    }

    const initSteps = () => {
        localStorage.removeItem(LOCAL_STEP);
        localStorage.removeItem(IS_COMPLETE);
        setStepIndex(getStep())
    }

    let count = -1;
    const getNextStep = (steps: TSteps, index: number): TStep | TStepViews => {

        const next = steps[index];
        if (typeof next === 'function') {
            return getNextStep(next(answers), 0);
        }

        count++;

        if (count === stepIndex) {
            return next;
        }

        return getNextStep(steps, index + 1);
    }

    const handleNext = () => {
        observer.shout('Save-Step', { answers, next });
        setStepIndex(stepIndex + 1);
    }

    const handleBack = () => {
        setStepIndex(stepIndex - 1);
    }

    const handleStart = () => {
        setStepIndex(0);
    }

    const nextStep = getNextStep(flowSteps, 0);
    const [next, header, per, actions] = typeof nextStep === 'string' ? [nextStep as TStepViews] : (nextStep || []);

    const value = {
        handleNext,
        handleBack,
        handleStart,
        hasBack: !!stepIndex && !!header,
        stepIndex,
        next,
        header,
        per,
        actions,
        clearCache,
        initSteps
    }

    return (
        <Provider value={value}>
            {children}
        </Provider>
    )
}


interface IProps {
    children: Elem;
}

interface IContext {
    handleNext: () => void;
    handleBack: () => void;
    handleStart: () => void;
    next: IFlowItem[] | TStepViews;
    header: THeader | undefined;
    per: number | undefined;
    actions?: Elem;
    hasBack: boolean;
    stepIndex: number;
    clearCache: () => void;
    initSteps: () => void;
}