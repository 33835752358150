import { H3 } from 'UIKit';
import './Sep.css';

interface ISep {
    children: Elem;
}

export const Sep = ({ children }: ISep) => {
    return (
        <div className='Sep'>
            <div className='grad'></div>
            <H3>{children}</H3>
            <div className='grad'></div>
        </div>
    )
}


export const SepLine = () => {
    return (
        <div className='SepLine'>
            
        </div>
    )
}