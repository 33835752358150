import './Box.css';

interface IProps {
    children: Elem
}

export const Box = ({ children }: IProps) => {
    return (
        <div className='Box'>
            <div className='sep' />
            <div className='sep' />
            <div className='sep' />

            {children}
        </div>
    )
}

interface IWrapProps {
    children: Elem;
    height: number;
}

export const Wrap = ({ children, height }: IWrapProps) => {
    return (
        <div style={{ display: 'grid', minHeight: height + "px" }}>
            {children}
        </div>
    )
}