export const toDateFormat = (date: Date | string) => {
    const d = new Date(date);

    const mm = d.getMonth();
    const dd = d.getDate();

    const MM = mm < 10 ? `0${mm}` : mm;
    const DD = dd < 10 ? `0${dd}` : dd;
    const YYYY = d.getFullYear();


    return `${MM}/${DD}/${YYYY}`;
}