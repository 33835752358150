import axios, { AxiosError } from "axios";

export const routes = {
  server: process.env.REACT_APP_SERVER_URL,
  upload: process.env.REACT_APP_Z3_ROUTES_UPLOADPDF,
  domains: process.env.REACT_APP_SERVER_ROUTES_DOMAINS,
  ml: process.env.REACT_APP_SERVER_ROUTES_MLV3,
  auth: process.env.REACT_APP_SERVER_ROUTES_AUTH,
  resultsAdmin: process.env.REACT_APP_SERVER_ROUTES_RESULTSADMIN,
};

type argsType = Parameters<(
  url: string,
  data?: null | object,
  config?: object,
  onCatch?: () => void
) => void>


class Api {

  send = async (
    method: 'get' | 'post' | 'put' | 'patch' | 'delete',
    ...args: argsType
  ) => {
    const [url, data, config, onCatch] = args;
    try {
      const resp = await axios[method](url, data || config, config);
      return resp.data;
    } catch (error: any) {
      if (onCatch) {
        throw this.getError(error);
      }
      await this.handleError(error);
    }
  };

  getError = (error: any) => {
    console.error(error);
    
    if (error.response) {
      const err = error.response?.data?.error || error.response?.data || "Error";

      return typeof(err) === 'string' ? err : "ERROR";

    } else if (error.message) {
      return `${error.message}`;
    } else if (error.request) {
      return error.request;
    } else {
      return "Error";
    }
  };

  handleError = async (error: AxiosError) => {
    const pop = async (msg: string) => {
      window.toastError(msg);
    };

    const message = this.getError(error);
    await pop(message);
    throw error;
  };

  getHeaders = (contentType = "application/json") => {
    return {
      headers: {
        "Content-Type": contentType,
      },
    };
  };

  get = async (...args: argsType) => this.send("get", ...args);
  post = async (...args: argsType) => this.send("post", ...args);
  delete = async (...args: argsType) => this.send("delete", ...args);
}

export const api = new Api();
