
import { Center, Text, H2, Rows, Step, Padding, H3, Btn, RowsFull, SepLine } from 'UIKit';
import { HomeLayout } from 'Screens/HomeLayout/HomeLayout';
import { Modal } from 'Shared/Modal/Modal';
import { useLang } from 'Lang/useLang';
import { useContext } from 'react';
import { stepsContext } from 'Questions/context/stepsContext';
import { useNavigate, useParams } from 'react-router-dom';


export const Followup = () => {
    const { handleStart } = useContext(stepsContext);
    const { domain } = useParams();
    const navigate = useNavigate();

    const { $ } = useLang();

    const handleCancel = () => {
        handleStart();
        navigate('/' + domain);
    }

    return (
        <div className="App">
            <HomeLayout>
                <Center>
                    <Rows>
                        <Step header={$('FOLLOW_TOP_HEADER')} per={100}>
                            <Padding>
                                <RowsFull>
                                    <H3 bold>{$('FOLLOW_HEADLINE')}</H3>
                                    <Text>{$('FOLLOW_CONTENT')}</Text>
                                    <SepLine />
                                    <Btn onClick={handleCancel}>{$('FOLLOW_BACK')}</Btn>
                                </RowsFull>
                            </Padding>
                        </Step>
                    </Rows>
                </Center>
            </HomeLayout>

        </div>
    );
}