import { useLang } from 'Lang/useLang';
import { useId, useRef } from 'react';
import { IconRadioOn, IconCheckOn } from 'UIKit';
import './CheckBox.css';


interface IProps {
    item: Item;
    theme?: 'basic' | 'action';
    onChange?: (item: Item, isChecked: boolean) => void;
    validation?: {
        error?: string
    };
    onClick?: () => void;
    className?: string;
    type?: 'radio' | 'checkbox';
    name?: string;
    disabled?: boolean;
    error?: boolean;
}

type Item = {
    checked: boolean;
    title: string;
    titleArgs?: any[];
    titleActions? : boolean;
}
export const CheckBox = ({ type = 'checkbox', item, theme, onChange, validation, onClick, className, name, disabled, error }: IProps) => {
    const forId = useId();
    const { $ } = useLang();
    const checked = !!item.checked;
    const isError = !!validation?.error;

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        onChange && onChange(item, e.target.checked);
    }

    const Icon = type === 'checkbox' ? IconCheckOn : IconRadioOn;
    const isActionInside = item.titleActions;

    return (
        <label
            data-error={error}
            data-disabled={disabled}
            data-type={type}
            data-checked={checked}
            htmlFor={forId}
            className={`CheckBox ${className || ''}`}
            data-theme={theme}
            data-validation={isError}
            data-no-hover={isActionInside}
        >
            <div className='icon_wrap' >
                {checked && <Icon />}
            </div>
            <div onClick={onClick}>{$(item.title, ...(item.titleArgs || []))}</div>
            <input
                id={forId}
                type={type}
                checked={checked}
                name={name}
                disabled={disabled}
                onChange={handleChange}
            />
        </label>
    )
}

export const RadioBtn = (props: IProps) => {
    return <CheckBox {...props} type='radio' />
}

export const CheckBoxAction = (props: IProps) => {
    return <CheckBox {...props} theme='action' />
}