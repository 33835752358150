import { Cube } from "../Cube/Cube"
import { IconAI, IconDietitian, IconDot, IconHere, IconReport } from "../Icon"
import './StepsSummary.css';
import { Text } from "../Typography/Typography";
import { useLang } from "Lang/useLang";

export const StepsStart = () => {
    const { $ } = useLang();
    return (
        <div className="Steps start" data-media="steps">
            <div className="wrap">
                <div>
                    <Cube
                        variant="default"
                        icon={IconAI}
                        title={$('STEPS_SUMMARY_AI')}
                    />
                </div>
                <div>
                    <Cube
                        variant="next"
                        icon={IconDietitian}
                        title={$('STEPS_SUMMARY_DIETITIAN')}
                    />
                </div>
                <div>
                    <Cube
                        variant="later"
                        icon={IconReport}
                        title={$('STEPS_SUMMARY_REPORT')}
                    />
                </div>
            </div>
            <div className="wrap">
                <div>
                    <IconHere />
                </div>
                <div>
                    <IconDot />
                </div>
                <div>
                    <IconDot />
                </div>
            </div>
            <div className="wrap">
                <div>
                    <Text bold>{$('STEPS_HERE')}</Text>
                </div>
                <div>
                    <Text>{$('STEPS_NEXT')}</Text>
                </div>
                <div>
                    <Text>{$('STEPS_LATER')}</Text>
                </div>
            </div>
        </div>
    )
}