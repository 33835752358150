import BG from 'Assets/SVG/bg.svg';

import './HomeLayout.css';
import { Header } from 'Wrappers/Header/Header';
import { Inner } from 'UIKit';

interface IProps {
    children: JSX.Element | JSX.Element[];
}
export const HomeLayout = ({ children }: IProps) => {
    return (
        <div className="HomeLayout">
            <div className='bg'>
                <img src={BG} alt='' />
            </div>
            <div className='content'>
                <Header />
                <main>
                    <Inner>
                        {children}
                    </Inner>
                </main>
            </div>
        </div>
    )
}