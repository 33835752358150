import { useState } from "react"
import { IUseInputResponse } from "./useInput.types";



export const useInput = (initialState?: string| number, placeholder?: string, validation?: string): IUseInputResponse=> {
    const [value, setValue] = useState(initialState || '');

    const empty = () => setValue('');

    return {
        value: value.toString(),
        onChange: setValue,
        p: placeholder,
        validation: { 
            type: 'input',
            error: '',
            prefix: validation
        },
        empty
    }
}