import { useState } from "react"
import { H3, Text } from "../Typography/Typography";
import { IconArrowDown } from "../Icon";

import './Accordion.css';


interface IProps {
    header: string;
    children?: Elem;
    content?: string;
}

export const Accordion = (props: IProps) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className="Accordion" data-open={isOpen}>
            <div className="header" onClick={() => setIsOpen(!isOpen)}>
                <H3 bold>{props.header}</H3>
                <div className="icon">
                    <IconArrowDown />
                </div>
            </div>
            <div className="content">
                <div className="inner">
                    {props.content ? <Text>{props.content}</Text> : props.children}
                </div>
            </div>
        </div>
    )
}