import { IAnswers, answersContext } from "Context/answersContext";
import { ElemType } from "./types/question.types";
import { useLang } from "Lang/useLang"
import { CheckBox, Dropdown, GridSplit, GridTriple, Input, Line, RadioBtn, RowsFullShort, Text, Error, InputPhone, Terms, Datepicker, GridTripleUp, Rows } from "UIKit"
import { useContext } from "react"
import { IFlowItem, IFlowItemErrArgs } from "Flow/types/flow.types";
import { flowSteps } from "Flow/Flow";
import { Observer } from "./Observer";
import { isItemError } from "./helpers/assertion";
import { TDateFormat, domainContext } from "Context/domainContext";
import { getDateFormat } from "./helpers/date";

interface IProps {
    flowItem: IFlowItem;
    index: number;
}

export const Question = ({ flowItem, index }: IProps) => {
    const domain = useContext(domainContext);
    const { answers, setAnswer } = useContext(answersContext);
    const { $, country } = useLang();

    const value = answers[flowItem.field];

    const isError = isItemError(flowItem);

    const toNumFunc = (val: unknown) => {
        if (!val) { return val }
        switch (typeof (val)) {
            case 'function': return val(answers)
            default: return val;
        }
    }

    const onChange = (updatedValue: any) => {
        setAnswer(flowItem.field, updatedValue).then(onChangeShout);
    }

    const onChangeArr = (updatedValue: any) => {
        const list = value as string[];
        if (list.includes(updatedValue)) {
            setAnswer(flowItem.field, list.filter(i => i !== updatedValue)).then(onChangeShout);
        } else {
            if (updatedValue === 'none') {
                setAnswer(flowItem.field, [updatedValue]).then(onChangeShout);
            } else {
                setAnswer(flowItem.field, [...list, updatedValue]).then(onChangeShout);
            }
        }
    }

    const onChangeShout = (newState: IAnswers) => {
        if (flowItem.onChange) {
            flowItem.onChange(newState, setAnswer);
        }
    }

    
    const renderElems = () => {
        return flowItem.elem?.map((item, index) => {
            switch (item.type) {
                case ElemType.Input:
                    return (
                        <Input
                            key={index}
                            value={value as string}
                            onChange={onChange}
                            error={isError}
                            min={toNumFunc(item.min)}
                            max={toNumFunc(item.max)}
                            maxLength={item.maxLength}
                            allow={item.allow}
                            type={item.inputType}
                            p={$(item.placeholder || `PLACEHOLDER_${flowItem.field.toUpperCase()}`)}
                        />
                    )
                case ElemType.Phone:
                    return (
                        <InputPhone
                            key={index}
                            value={value as string}
                            onChange={onChange}
                            error={isError}
                            p={$(item.placeholder || `PLACEHOLDER_${flowItem.field.toUpperCase()}`)}
                        />
                    )
                case ElemType.Radio:
                    return (
                        <RadioBtn
                            key={index}
                            item={{
                                checked: value === item.value,
                                title: item.label || ''
                            }}
                            name={flowItem.field}
                            onChange={() => onChange(item.value)}
                            error={isError}
                        />
                    )
                case ElemType.Checkbox:
                    return (
                        <CheckBox
                            key={index}
                            item={{
                                checked: (value as string[]).includes(item.value as string),
                                title: item.label || ''
                            }}
                            disabled={(value as string[]).includes('none') && item.value !== 'none'}
                            onChange={() => onChangeArr(item.value)}
                            error={isError}
                        />
                    )
                case ElemType.Dropdown:
                    return (
                        <Dropdown
                            key={index}
                            label={item.label}
                            selected={value as string || item.value}
                            list={item?.options?.map((t: any) => (
                                {
                                    id: t.id,
                                    value: $(t.value.replace(" ", "_"))
                                }
                            )) || []}
                            onChange={onChange}
                            error={isError}
                            hasBorder
                        />
                    )
                case ElemType.Date:
                    return (
                        <Datepicker
                            key={index}
                            onChange={onChange}
                            error={isError}
                            value={new Date(answers[flowItem.field] as string)}
                        />
                    )
                case ElemType.Observer:
                    return (
                        <Observer
                            field={flowItem.field}
                            flowItem={flowItem}
                        />
                    )
                default:
                    return <></>
            }
        })
    }

    const getTitle = () => {
        if (flowItem.titleCond) {
            return flowItem.titleCond(answers);
        }
        return flowItem.title || `TITLE_${flowItem.field.toUpperCase()}`;
    }

    const getTitleArgs = () => {
        if (!flowItem.titleArgs) { return [] }

        return flowItem.titleArgs.map(field => {
            return answers[field];
        })
    }

    const title = $(getTitle(), ...getTitleArgs());
    flowItem.titleValue = title;

    if (flowItem.cond && !flowItem.cond(answers, domain!, country)) {
        return <></>
    }

    if (flowItem.external) {
        return <></>
    }

    return (
        <div className="question" style={{ animationDelay: ((index + 1) * .2).toString() + 's' }}>
            <RowsFullShort>
                <Text bold>{title}</Text>
                <div>
                    <Wrapper flex={flowItem.flex}>
                        {renderElems()}
                    </Wrapper>
                </div>
                {isError && (
                    <Error>{$(`MSG_ERROR_${flowItem.error?.toUpperCase()}`, ...getErrorArgs(flowItem, domain?.dateFormat))}</Error>
                )}

            </RowsFullShort>
        </div>
    )
}

const getErrorArgs = (flowItem: IFlowItem, dateFormat?: TDateFormat) => {
    if(flowItem.validateArgs) {
        const args: IFlowItemErrArgs = flowItem.validateArgs;
        return Object.keys(args).map((key: string) => {
            const arg = args[key as keyof IFlowItemErrArgs];
            if(arg && (key === 'minDate' || key === 'maxDate')) {
                return getDateFormat(arg as Date, dateFormat);
            }
            if(key === 'min' || key === 'max') {
                return (arg as number).toString();
            }
        })
    }
    return []
}

const Wrapper = ({ children, flex }: { children: Elem, flex: IFlowItem['flex'] }) => {
    switch (flex) {
        case 'line': return <Line>{children}</Line>;
        case 'rows': return <RowsFullShort>{children}</RowsFullShort>;
        case 'triple': return <GridTriple>{children}</GridTriple>;
        case 'triple-up': return <GridTripleUp>{children}</GridTripleUp>;
        case 'split': return <GridSplit>{children}</GridSplit>;
        default: return <RowsFullShort>{children}</RowsFullShort>;
    }
}