import { TDateFormat } from "Context/domainContext";

export const getDateFormat = (date: Date, format?: TDateFormat) => {
    const d = new Date(date);

    const dd = d.getDate();
    const mm = d.getMonth() + 1;

    const day = dd < 10 ? "0" + dd : dd;
    const month = mm < 10 ? "0" + mm : mm;
    const year = d.getFullYear();
    
    if(format === "DD/MM/YYYY") {
        return `${day}/${month}/${year}`
    }
    return `${month}/${day}/${year}`
}