import { Between, GifRolling, IconAUFlag, IconArrowR, NoWrap, Rows, RowsShort, Text } from "UIKit";
import { Button } from "../Typography/Typography"
import './Btn.css';

type Elem = JSX.Element | JSX.Element[] | string;
interface IProps {
    disabled?: boolean;
    onClick: (e: Event) => void;
    children?: Elem;
    i?: () => JSX.Element;
    isNext?: boolean;
    isResponsive?: boolean;
    isLoading?: boolean;
    nextIcon?: () => JSX.Element;
    type?: string;
    addClass?: string;
    stopPropagation?: boolean;
}

export const Btn = (props: IProps) => {
    const {
        disabled, onClick, children, i, isNext,
        isResponsive, isLoading, nextIcon, type, addClass,
        stopPropagation
    } = props;

    const isDisabled = disabled;

    const renderIcon = () => {
        if (i) {
            return i();
        }
        return <></>
    }

    const renderChildren = () => {
        if(children) {
            return (
                <div className="text">
                    {children}
                </div>
            )
        }
        return <></>
    }

    const renderChild = () => {
        return (
            <NoWrap>
                {renderIcon()}
                {renderChildren()}
            </NoWrap>
        )
    }

    const getClass = () => {
        const cls = ['Btn'];
        if (addClass) {
            cls.push(addClass);
        }
        if (!children) {
            cls.push('no-children');
        }

        if (isLoading) {
            cls.push('loading');
        }

        return cls.join(' ');
    }

    const handleClick = (e: Event) => {
        if (isLoading) { return }
        if(stopPropagation) {
            e.stopPropagation();
        }

        onClick(e);
    }
    return (
        <Button onClick={handleClick} className={getClass()} type={type} disabled={isDisabled} media={isResponsive ? 'btn' : null}>
            {isNext || nextIcon ? (
                <Between>
                    {renderChild()}
                    <div className="actions-wrap">
                        <div className="loader">
                            <GifRolling />
                        </div>
                        <div className="next-icon">
                            {nextIcon ?
                                nextIcon()
                                :
                                <IconArrowR />}
                        </div>
                    </div>
                </Between>
            ) : renderChild()}

        </Button>
    )
}

export const BtnNext = (props: IProps) => {
    return <Btn {...props} isNext />
}

export const LinkBtn = (props: IProps) => {
    return <Btn {...props} addClass="link-btn" stopPropagation />
}

export const LightBtn = (props: IProps) => {
    return <Btn {...props} addClass="light-btn" />
}

export const BtnSecondary = (props: IProps) => {
    return <Btn {...props} addClass="secondary-btn" />
}

export const BtnCancel = (props: IProps) => {
    return <Btn {...props} addClass="cancel-btn" />
}

export const BtnInfo = (props: IProps) => {
    return <Btn {...props} addClass="info-btn" />
}

interface IBtnBig { 
    title: string;
    info: string;
    i: Elem;
}
export const BtnBig = ({ title, info, i }: IBtnBig) => {
    return (
        <button className="BtnBig">
            <NoWrap>
                {i}
                <RowsShort>
                    <Text>{title}</Text>
                    <Text bold>{info}</Text>
                </RowsShort>
            </NoWrap>
        </button>
    )
}

interface ITouchable {
    children: Elem;
    onClick: () => void;
}
export const Touchable = ({ children, onClick }: ITouchable) => {
    return (
        <div className="Touchable" onClick={onClick}>
            {children}
        </div>
    )
}