import { answersOptions } from "Context/answersContext";
import { IFlowItem } from "Flow/types/flow.types";
import { answersMaps } from "Questions/helpers/maps";
import { ElemType } from "Questions/types/question.types";
import { addDays, addMonths } from "helpers/date";

export const Step9: IFlowItem[] = [
    {
        field: 'isBreastFeeding',
        flex: 'triple',
        elem: answersMaps.optionsToElem(answersOptions.yesNo, ElemType.Radio)
    },
    {
        field: 'infantFormula',
        titleArgs: ['infantFirstName'],
        flex: 'triple-up',
        elem: answersMaps.optionsToElem(answersOptions.formulas, ElemType.Checkbox)
    }
]