import { answersOptions } from "Context/answersContext";
import { IFlowItem } from "Flow/types/flow.types";
import { answersMaps } from "Questions/helpers/maps";
import { ElemType } from "Questions/types/question.types";

export const Step1: IFlowItem[] = [
    {
        field: 'infantAge',
        elem: answersMaps.optionsToElem(answersOptions.infantAge, ElemType.Radio)
    },
    {
        field: 'infantFirstName',
        cond: (answers) => {
            return answers.infantAge !== '' && answers.infantAge !== 'no_born'
        },
        elem: answersMaps.toInputLetters()
    },
    {
        field: 'placeOfBirth',
        titleArgs: ['infantFirstName'],
        cond: (answers) => {
            return answers.infantAge !== '' && answers.infantAge !== 'no_born'
        },
        elem: answersMaps.optionsToElem(answersOptions.placeOfBirth, ElemType.Radio)
    },
    {
        field: 'iam',
        flex: 'triple',
        elem: answersMaps.optionsToElem(answersOptions.iam, ElemType.Radio)
    }
]