import { answersOptions } from "Context/answersContext";
import { IFlowItem } from "Flow/types/flow.types";
import { answersMaps } from "Questions/helpers/maps";
import { ElemType } from "Questions/types/question.types";

export const Step3b: IFlowItem[] = [
    {
        field: 'infantAllergies',
        titleArgs: ['infantFirstName'],
        elem: answersMaps.optionsToElem(answersOptions.yesNo, ElemType.Radio)
    }
]