

import { useLang } from "Lang/useLang"
import { Center, CenterAlign, Rows, Text, GifRolling, Error, Padding } from "UIKit"
import { Icon } from "UIKit/Elements/Icon/Icon"

interface IResultsLoaderProps {
    error: boolean;
}

export const ResultsLoader = ({ error }: IResultsLoaderProps) => {
    const { $ } = useLang();

    return (
        <Center>
            <Rows>
                {!error && (
                    <>
                        <CenterAlign>
                            <Icon size='medium'>
                                {<GifRolling />}
                            </Icon>
                        </CenterAlign>
                        <Text mark>{$('TITLE_GENERATE')}</Text>
                    </>
                )}
                {error && (
                    <Padding>
                        <CenterAlign>
                            <Error>{$('RESULTS_ERROR_GEN')}</Error>
                        </CenterAlign>
                    </Padding>
                )}
            </Rows>
        </Center>
    )
}