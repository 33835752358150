import { IAnswers, answersContext } from "Context/answersContext"
import { IFlowItem } from "Flow/types/flow.types"
import { useContext, useEffect } from "react"
import { observerContext } from "./context/observerContext";

interface IProps {
    field: keyof IAnswers;
    flowItem: IFlowItem;
}
export const Observer = ({ field, flowItem }: IProps) => {
    const { shout } = useContext(observerContext);
    const { answers } = useContext(answersContext);

    useEffect(() => {
        shout(field, flowItem);
    }, [answers[field]])

    return <></>
}