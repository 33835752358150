interface IProps {
    children: Elem;
    to: string
}

export const OpenMail = ({ children, to }: IProps) => {
    return (
        <a target="_blank" href={`https://mail.google.com/mail/?view=cm&fs=1&to=${to}`}>
           {children}
        </a>
    )
}