
import { answersOptions } from "Context/answersContext";
import { IFlowItem } from "Flow/types/flow.types";
import { answersMaps } from "Questions/helpers/maps";
import { ElemType } from "Questions/types/question.types";
import { addDays, addMonths } from "helpers/date";

export const Step8: IFlowItem[] = [
    {
        field: 'infantDateOfBirth',
        titleCond() {
            return 'TITLE_INFANTDATEOFBIRTH'
        },
        titleArgs: ['infantFirstName'],
        validate: ['date'],
        validateArgs: {
            minDate: addMonths(new Date(), -6),
            maxDate: addMonths(new Date(), 0)
        },
        elem: answersMaps.toDate()    
    },
    {
        field: 'gender',
        titleArgs: ['infantFirstName'],
        flex: 'split',
        elem: answersMaps.optionsToElem(answersOptions.gender, ElemType.Radio)
    },
    {
        field: 'gestationalAge',
        titleCond(answers) {
            if(answers.infantAge === 'no_born') {
                return 'TITLE_GESTATIONALAGE'
            }
            return 'TITLE_GESTATIONALAGE_BORN'
        },
        titleArgs: ['infantFirstName'],
        elem: answersMaps.toInput(20, 43, 'number')
    },
    {
        field: 'isCesarian',
        titleCond(answers) {
            if(answers.infantAge === 'no_born') {
                return 'TITLE_ISCESARIAN'
            }
            return 'TITLE_ISCESARIAN_BORN'
        },
        titleArgs: ['infantFirstName'],
        flex: 'split',
        elem: answersMaps.optionsToElem(answersOptions.yesNo, ElemType.Radio)
    },
    {
        field: 'weightKg',
        titleArgs: ['infantFirstName'],
        cond:(_, domain) => (domain?.weight === 'kg'),
        elem: answersMaps.toInput(1, 7, 'number')
    },
    {
        field: 'weightPound',
        titleArgs: ['infantFirstName'],
        cond:(_, domain) => (domain?.weight === 'pounds'),
        elem: answersMaps.toInput(2, 16, 'number')
    }
]