
import { Center, Text, Rows, Step, Padding, Btn, RowsFull, SepLine, IconComplete, H1, BtnCancel, Wrap, CenterAlign, StepsSummary, MarginTop } from 'UIKit';
import { HomeLayout } from 'Screens/HomeLayout/HomeLayout';
import { useLang } from 'Lang/useLang';
import { Link } from 'react-router-dom';
import { StepsStart } from 'UIKit/Elements/StepsSummary/StepsStart';
import peanut from 'Assets/Pics/peanuts.png';

export const Welcome = () => {
    const { $ } = useLang();

    return (
        <div className="App">
            <HomeLayout>
                <Center>
                    <Rows>
                        <Step>
                            <Wrap height={450}>
                                <Padding>
                                    <Rows>
                                        <Padding>
                                            <H1 bold mark center>{$('WELCOME_MESSAGE')}</H1>
                                        </Padding>
                                        <StepsStart />
                                        <Padding>
                                            <Text center>{$('WELCOME_ABOUT')}</Text>
                                        </Padding>
                                    </Rows>
                                </Padding>
                                <img src={peanut} alt='' />
                            </Wrap>
                            <SepLine />
                            <Padding>
                                <RowsFull>
                                    <Link to={"start"} className='contents'>
                                        <Btn onClick={() => { }}>{$('WELCOME_START_BTN')}</Btn>
                                    </Link>
                                </RowsFull>
                            </Padding>
                        </Step>
                    </Rows>
                </Center>
            </HomeLayout>

        </div>
    );
}