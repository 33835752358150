import { TSteps } from "./types/flow.types";
import { Step1 } from "./steps/step1";
import { Step2 } from "./steps/step2";
import { Step3a } from "./steps/step3a";
import { Step3b } from "./steps/step3b";
import { Step3c } from "./steps/step3c";
import { Step4 } from "./steps/step4";

import { IAnswers } from "Context/answersContext";
import { Terms } from "UIKit";
import { Step5 } from "./steps/step5";
import { Step6 } from "./steps/step6";
import { Step7 } from "./steps/step7";
import { Step8 } from "./steps/step8";
import { Step9 } from "./steps/step9";
import { Step10 } from "./steps/step10";
import { Step11 } from "./steps/step11";


export const flowSteps: TSteps = [
    [Step1, 'HEADLINE_GET_STARTED', 10],

    (answers: IAnswers) => {
        if(answers.infantAge === 'no_born') {
            return [
                [Step3a, ["HEADLINE_GET_STARTED", "INFO_GET_STARTED"], 20, Terms],
                [Step4, "HEADLINE_KEEP_UP", 40],
                [Step5, "HEADLINE_HALF_WAY", 50],
                [Step6, "HEADLINE_THREE_QUARTER", 75],
                [Step7, "HEADLINE_THREE_QUARTER", 75],
                '/survey_done'
            ]
        } else {
            
            return [
                [Step3a, ["HEADLINE_GET_STARTED", "INFO_GET_STARTED"], 20, Terms],
                [Step8, "HEADLINE_KEEP_UP", 30],
                [Step3b, "HEADLINE_KEEP_UP", 35],
                (answers) => {
                    if(answers.infantAllergies === 'yes') {
                        return ['/followup']
                    }

                    return [
                        [Step5, "HEADLINE_HALF_WAY", 40],
                        [Step6, "HEADLINE_THREE_QUARTER", 55],
                        [Step7, "HEADLINE_THREE_QUARTER", 65],
                        [Step9, "HEADLINE_ALMOST_DONE", 75],
                        [Step10, "HEADLINE_ALMOST_DONE", 85],
                        [Step11, "LAST_ONES", 95],
                        '/survey_done'
                    ]
                }
            ]
        }
    }
]