import './Input.css';
import React, { InputHTMLAttributes, forwardRef, useEffect, useRef, useState } from 'react';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css'
import { IconCopy } from '../Icon';
import { Touchable } from '../Btn/Btn';
import { useLang } from 'Lang/useLang';

interface IProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
    p?: string;
    focus?: boolean;
    error?: boolean;
    tag?: Elem;
    min?: number,
    max?: number,
    international?: boolean;
    onChange: (val: string) => void;
    empty?: () => void;
    isCopy?: boolean;
    allow?: TInputAllow;
}

export type TInputAllow = 'numbers' | 'letters';

export const Input = forwardRef((props: IProps, ref: React.Ref<HTMLInputElement>) => {
    const { p: placeholder, max, min, tag, onChange, focus, error, empty, isCopy, allow, ...rest } = props;
    const inputRef = useRef<HTMLInputElement>(null);
    const timer = useRef<ReturnType<typeof setTimeout> | null>(null);
    const Tag = tag || "input";
    const [copied, setCopied] = useState(false);
    const { $ } = useLang();

    useEffect(() => {
        if (copied) {
            window.toast($('TOAST_VALUE_COPY'), 'ok')
            setTimeout(() => setCopied(false), 4000);
        }
    }, [copied])

    const isReadOnly = isCopy;

    const timerMS = window.innerWidth < 600 ? 1200 : 700;

    useEffect(() => {
        minMax(rest.value as string, false);

        if (inputRef.current) {
            inputRef.current.addEventListener("wheel", function (event) {
                if (inputRef.current && inputRef.current.type === "number") {
                    inputRef.current.blur();
                }
            });
        }

    }, [])

    useEffect(() => {
        if (focus && inputRef?.current) {
            inputRef.current?.focus();
        }
    }, [focus])
    const handleChange = (e: any) => {
        if (isReadOnly) { return }
        if (!e) { return }

        let val = typeof (e) === 'string' ? e : e?.target.value;
        const inputType = typeof (e) === 'string' ? false : e.nativeEvent.inputType;

        switch (allow) {
            case 'numbers':
                if ((val && !val.match(/^-?\d*\.?\d*$/))) return;
                break;
            case 'letters':
                if ((val && !val.match(/^[A-Za-záéíóúüñç\s]+$/i))) return;
                break;
        }

        callChange(val);


        minMax(val, inputType);
    }

    const handleFocus = () => {
        if (inputRef?.current && props.type === 'number') {
            inputRef.current?.select();
        }
    }

    const minMax = (val: string, inputType: boolean) => {
        const isMax = max != undefined;
        const isMin = min != undefined;

        if (isMax || isMin) {

            clearTimeout(timer.current || 0)

            const trig = () => {
                let v = +val;
                if (isMax && +val > max) { v = max };
                if (isMin && +val < min) { v = min };
                callChange(v.toString());
            }

            if (inputType) {
                timer.current = setTimeout(trig, timerMS);
            } else {
                trig();
            }
        }
    }
    const callChange = (val: string) => {
        onChange && onChange(val);
    }

    const handleCopy = () => {
        if (!inputRef.current) { return }

        inputRef.current.select();
        inputRef.current.setSelectionRange(0, 99999); // For mobile devices

        // Copy the text inside the text field
        navigator.clipboard.writeText(inputRef.current.value);
        setCopied(true);
    }

    return (
        <div className='Input' data-error={error}>
            {React.createElement(Tag, {
                ref: ref || inputRef,
                ...rest,
                placeholder,
                onChange: handleChange,
                onFocus: handleFocus,
                onMouseUp: handleFocus
            })}
            {isCopy && (
                <div className={`is_copy ${copied ? 'selected' : ''}`}>
                    <Touchable onClick={handleCopy}>
                        <IconCopy />
                    </Touchable>
                </div>
            )}
        </div>
    )
})


export const InputPhone = (props: IProps) => {
    return <Input {...props} tag={PhoneInput} international />
}