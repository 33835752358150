import { IAnswers } from "Context/answersContext";
import { TInputAllow } from "UIKit";

export enum ElemType {
    Input,
    Radio,
    Checkbox,
    Dropdown,
    Phone,
    Observer,
    Date
}

export type ElemItem = {
    type: ElemType;
    label?: string;
    value?: string;
    placeholder?: string;
    options?: ElemOption[];
    minDate?: Date;
    maxDate?: Date;
    min?: NumFunc;
    max?: NumFunc;
    maxLength?: number;
    allow?: TInputAllow;
    inputType?: InputType
}

export type ElemOption = {
    id: string;
    value: string;
}

export type InputType = 'text' | 'email' | 'password' | 'number';

export type NumFunc = number | ((answers: IAnswers) => number);
