import { answersOptions } from "Context/answersContext";
import { IFlowItem } from "Flow/types/flow.types";
import { answersMaps } from "Questions/helpers/maps";
import { COUNTRIES } from "types/countries";

export const Step3a: IFlowItem[] = [
    {
        field: 'firstName',
        elem: answersMaps.toInputLetters()
    },
    {
        field: 'lastName',
        elem: answersMaps.toInputLetters()
    },
    {
        field: 'phoneNumber',
        validate: ['phone'],
        elem: answersMaps.toPhone()
    },
    {
        field: 'state',
        elem: answersMaps.optionsToDropdown(answersOptions.state),
        cond: (answers, domain, country) => {
            return country === COUNTRIES.US
        },
    },
    {
        field: 'email',
        validate: ['email'],
        elem: answersMaps.toEmail()
    },
    {
        field: 'terms',
        external: true
    }
]