import './Grid.css';

interface IProps {
    children: Elem;
    addClass?: string;
}
export const Grid = ({ children, addClass }: IProps) => {
    return (
        <div className={`Grid ${addClass}`}>
            {children}
        </div>
    )
}

export const GridTriple = (props: IProps) => {
    return  <Grid {...props} addClass='triple' />
}

export const GridTripleUp = (props: IProps) => {
    return  <Grid {...props} addClass='triple-up' />
}

export const GridSplit = (props: IProps) => {
    return  <Grid {...props} addClass='split' />
}