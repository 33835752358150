import './Space.css';

interface ISpace {
    children: Elem;
    className?: string;
}

export const Space = ({ children, className }: ISpace) => {
    return (
        <div className={className}>
            {children}
        </div>
    )
}

export const Padding = (props: ISpace) => {
    return <Space {...props} className="Padding" />
}

export const PaddingTop = (props: ISpace) => {
    return <Space {...props} className="Padding-top" />
}

export const Margin = (props: ISpace) => {
    return <Space {...props} className="Margin" />
}

export const MarginTop = (props: ISpace) => {
    return <Space {...props} className="Margin-top" />
}

export const MarginBig = (props: ISpace) => {
    return <Space {...props} className="Margin-big" />
} 