import { IAnswers, answersContext } from "Context/answersContext";
import { IFlowItem, TStepViews } from "Flow/types/flow.types";
import { useLang } from "Lang/useLang"
import { stepsContext } from "Questions/context/stepsContext";
import { toDateFormat } from "Utils/data";
import { observer } from "Utils/observer";
import { useContext, useEffect, useRef } from "react";

export const useGTM = () => {
    const { next, stepIndex, header } = useContext(stepsContext);
    const { answers } = useContext(answersContext);

    const { getCountries, country, getLangs, langId } = useLang();
    const countryGTM = getCountries().find(c => c.id === country)?.gtm;
    const langGTM = getLangs().find(l => l.id === langId)?.gtm;
    const envGTM = process.env.REACT_APP_ENVIRONMENT;
    const nextRef = useRef<IFlowItem[] | TStepViews>();
    const headerRef = useRef<string | undefined>();
    const answersRef = useRef<IAnswers>();

    const [title] = Array.isArray(header) ? header : [header];
    nextRef.current = next;
    headerRef.current = title;
    answersRef.current = answers;

    useEffect(() => {
        observer.listen("Set-Answer", (data) => {
            const question = (nextRef.current as IFlowItem[]).find(i => i.field === data.field);
            const field = data.field as keyof IAnswers;
            if (isExcludedField(field)) return;

            data.value = normalizeFields(field, data.value);


            window.dataLayer.push({
                'event': 'answer_select',
                'field_name': data.field,
                'field_value': data.value,
                'question_text': question?.titleValue,
                'step_name': header
            });
        })

        observer.listen('Save-Step', ({
            answers,
            next
        }: {
            answers: IAnswers,
            next: TStepViews | IFlowItem[]
        }) => {
            if(!Array.isArray(next)) { return }

            (next as IFlowItem[]).forEach(i => {
                if (!isExcludedField(i.field)) {
                    const value = normalizeFields(i.field, answers[i.field]);

                    window.dataLayer.push({
                        'event': 'answer_save',
                        'field_name': i.field,
                        'field_value': value,
                        'question_text': i.titleValue,
                        'step_name': header
                    })
                }
            })
        })

        observer.listen('Survey-Complete', ({ short }) => {
            window.dataLayer.push({
                'event': 'survey_complete',
                'survey_id': short
            });
        })

        observer.listen('region_picker_open', () => {
            window.dataLayer.push({
                'event': 'region_picker_open'
            });
        })

        observer.listen('region_picker_select', ({ lang, country }) => {
            window.dataLayer.push({
                'event': 'region_picker_select',
                'language': lang,
                'region': country,
            });
        })
    }, [])

    useEffect(() => {
        if (langGTM && countryGTM) {
            window.dataLayer.push({
                'language': langGTM,
                'region': countryGTM,
                'environment': envGTM
            });
            localStorage.setItem("GTM_LANGUAGE", langGTM);
            localStorage.setItem("GTM_REGION", countryGTM);
            localStorage.setItem("GTM_ENV", envGTM as string);
        }
    }, [countryGTM, langGTM])


    useEffect(() => {
        if (typeof (next) === 'string') return;
        if (!answersRef.current) return;

        window.dataLayer.push({
            'event': `survey_step_${stepIndex + 1}`,
            'step_name': header,
            'survey_flow': answersRef.current?.infantAge === "no_born" ? "unborn" : "born"
        });
    }, [stepIndex])

    const isExcludedField = (field: keyof IAnswers) => {
        return [
            "email",
            "firstName",
            "lastName",
            "infantFirstName",
            "phoneNumber"
        ].includes(field)
    }

    const normalizeFields = (field: keyof IAnswers, value: any) => {
        if ([
            "infantDueDateOfBirth",
            "infantDateOfBirth"
        ].includes(field)) {
            return toDateFormat(value);
        }

        return value;
    }
}