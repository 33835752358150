import { RowsFull, RowsFullHigh } from "UIKit/Layouts/Line/Line"
import { Question } from "./Question"

import './Questions.css';
import { BtnNext } from "../UIKit/Elements/Btn/Btn";
import { IFlowItem } from "Flow/types/flow.types";
import { useValidator } from "./hooks/useValidator";
import { ObserverProvider } from "./context/observerContext";
import { useLang } from "Lang/useLang";

interface IProps {
    flowItems: IFlowItem[];
    onNext: () => void;
    Actions?: Elem;
}

export const Questions = ({ flowItems, onNext, Actions }: IProps) => {
    const { validateScan, isValid, isEmpty, clear } = useValidator(flowItems);

    const { $ } = useLang();

    validateScan();

    const handleClick = () => {
        if (!isValid()) { return }

        clear();
        onNext();
    }
    return (
        <ObserverProvider>
            <div className="Questions">
                <div className="list">
                    <RowsFullHigh>
                        {flowItems.map((item, index) => (
                            <Question key={item.field} flowItem={item} index={index} />
                        ))}
                    </RowsFullHigh>
                </div>
                <div className="actions">
                    <RowsFull>
                        {Actions && <Actions />}
                        <BtnNext onClick={handleClick} disabled={isEmpty()}>{$('QUESTIONS_CONTINUE')}</BtnNext>
                    </RowsFull>
                </div>
            </div>
        </ObserverProvider>

    )
}