import { answersOptions } from "Context/answersContext";
import { IFlowItem } from "Flow/types/flow.types";
import { answersMaps } from "Questions/helpers/maps";
import { ElemType } from "Questions/types/question.types";

export const Step10: IFlowItem[] = [
    {
        field: 'specialFormula',
        titleArgs: ['infantFirstName'],
        flex: 'triple-up',
        elem: answersMaps.optionsToElem(answersOptions.formulas, ElemType.Checkbox)
    }
]       