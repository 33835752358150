import { SmallText } from '../Typography/Typography';
import './Cube.css';

interface ICubeProps {
    icon: Elem;
    title: string;
    variant: "default" | "done" | "next" | "later"
}
export const Cube = ({ icon, title, variant }: ICubeProps) => {
    return (
        <div className="Cube" data-variant={variant}>
            <div className="icon">
                {icon()}
            </div>
            <div className="title">
                <SmallText>{title}</SmallText>
            </div>
        </div>
    )
}