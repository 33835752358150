
import { observer } from "Utils/observer";
import { createContext, useEffect, useState } from "react";

interface IProps {
    children: Elem;
}

interface IAnswersContext {
    answers: IAnswers;
    setAnswer: (field: keyof IAnswers, value: any) => Promise<IAnswers>;
    result: IResult;
    setResult: (res: IResult) => void;
    clearCache: () => void;
    initResults: () => void;
}


export interface IML {
    gender: typeof answersOptions.gender[number] | '';
    genderKnown: typeof answersOptions.genderKnown[number] | '';
    weight: typeof answersOptions.infantWight[number] | '';
    weightKg: number;
    weightPound: number;
    infantAge: typeof answersOptions.infantAge[number] | '';
    motherConditions: typeof answersOptions.conditions[number][];
    conditionsDuringPregnant: typeof answersOptions.yesNo[number] | '';
    antibiotics: typeof answersOptions.prescription[number] | '';
    fatherConditions: typeof answersOptions.conditions[number][];
    infantHasAllergies: typeof answersOptions.yesNo[number] | '';
    infantDateOfBirth: Date | '';
    infantDueDateOfBirth: Date | '';
    placeOfBirth: typeof answersOptions.placeOfBirth[number] | '';
    weeksPregnant: number;
    gestationalAge: number;
    isCesarian: typeof answersOptions.yesNo[number] | '';
    isSiblings: typeof answersOptions.yesNo[number] | '';
    isSmoking: typeof answersOptions.yesNo[number] | '';
    siblingsCount: number;
    siblingsConditions: typeof answersOptions.conditions[number][];
    siblingsConditionsCount: number;
    isBreastFeeding: typeof answersOptions.yesNo[number] | '';
    infantFormula: typeof answersOptions.formulas[number][];
    specialFormula: typeof answersOptions.formulas[number][];
    eczemaSigns: typeof answersOptions.yesNo[number] | '';
    eczemaSignsDiagnose: typeof answersOptions.yesNo[number] | '';
    eczemaSystematicAntibiotics: typeof answersOptions.yesNo[number] | '';
    eczemaTopicalAntibiotics: typeof answersOptions.yesNo[number] | '';
    infantAllergies: typeof answersOptions.yesNo[number] | '';
    childEnvironment: typeof answersOptions.environment[number] | '';
}

export interface IAnswers extends IML {
    terms: boolean;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    email: string;
    state: typeof answersOptions.state[number] | '';
    parental: typeof answersOptions.parental[number] | '';
    infantFirstName: string;
    iam: typeof answersOptions.iam[number] | '';
}

export interface IResult {
    ml: {
        AD: TGrade;
        FA: TGrade
    };
    short: string;
    answers: IAnswers | {};
}

export type TGrade = 'na' | 'low' | 'high'

export const answersOptions = {
    iam: ['bio_mother', 'bio_father', 'other'] as const,
    gender: ['male', 'female'] as const,
    genderKnown: ['boy', 'girl', 'gender_unknown'] as const,
    infantWight: ['light', 'chunky', 'massive', 'skeleton'] as const,
    infantAge: ['no_born', 'just_born', 'pre_solids', 'eat_solids'] as const,
    conditions: ['atopic', 'food_allergy', 'asthma', 'hay_fever', 'not_sure', 'none'] as const,
    parental: ['mother', 'father', 'other'] as const,
    yesNo: ['yes', 'no'] as const,
    prescription: ['zero', 'one', 'two', 'three', 'three_plus'] as const,
    placeOfBirth: ['city', 'suburbs', 'country_side'] as const,
    formulas: ['first_few_days', 'zero_one', 'one_two', 'two_three', 'three_four', 'four_plus', 'none'] as const,
    environment: ['rural', 'urban', 'suburb'] as const,
    state: [
        "Alabama",
        "Alaska",
        "Arizona",
        "Arkansas",
        "California",
        "Colorado",
        "Connecticut",
        "Delaware",
        "Florida",
        "Georgia",
        "Hawaii",
        "Idaho",
        "Illinois",
        "Indiana",
        "Iowa",
        "Kansas",
        "Kentucky",
        "Louisiana",
        "Maine",
        "Maryland",
        "Massachusetts",
        "Michigan",
        "Minnesota",
        "Mississippi",
        "Missouri",
        "Montana",
        "Nebraska",
        "Nevada",
        "New Hampshire",
        "New Jersey",
        "New Mexico",
        "New York",
        "North Carolina",
        "North Dakota",
        "Ohio",
        "Oklahoma",
        "Oregon",
        "Pennsylvania",
        "Rhode Island",
        "South Carolina",
        "South Dakota",
        "Tennessee",
        "Texas",
        "Utah",
        "Vermont",
        "Virginia",
        "Washington",
        "West Virginia",
        "Wisconsin",
        "Wyoming"
    ] as const
}



const defaultAnswers: IAnswers = {
    terms: false,
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    state: '',
    gender: '',
    genderKnown: '',
    weight: '',
    weightKg: 1,
    weightPound: 2,
    infantAge: '',
    motherConditions: [],
    conditionsDuringPregnant: '',
    antibiotics: '',
    fatherConditions: [],
    parental: '',
    infantFirstName: '',
    infantHasAllergies: '',
    infantDateOfBirth: new Date(),
    infantDueDateOfBirth: new Date(),
    placeOfBirth: '',
    weeksPregnant: 25,
    gestationalAge: 25,
    isCesarian: '',
    isSiblings: '',
    isSmoking: '',
    siblingsCount: 0,
    siblingsConditions: [],
    siblingsConditionsCount: 0,
    isBreastFeeding: '',
    infantFormula: [],
    specialFormula: [],
    eczemaSigns: '',
    eczemaSignsDiagnose: '',
    eczemaSystematicAntibiotics: '',
    eczemaTopicalAntibiotics: '',
    iam: '',
    infantAllergies: '',
    childEnvironment: '',
}

export const defaultResults: IResult = {
    short: '',
    ml: {
        AD: 'na',
        FA: 'na'
    },
    answers: {}
}

export const answersContext = createContext<IAnswersContext>({
    answers: defaultAnswers,
    setAnswer: () => { return new Promise((res) => res(defaultAnswers)) },
    result: defaultResults,
    setResult: () => { },
    clearCache: () => { },
    initResults: () => { }
});


const LOCAL_ANSWERS = 'CURRENT_ANSWERS';

const getLocalAnswers = (): IAnswers => {
    const answers = localStorage.getItem(LOCAL_ANSWERS);
    if (answers) {
        return JSON.parse(answers) as IAnswers;
    }
    return defaultAnswers;
}

const setLocalAnswers = (answers: IAnswers) => {
    localStorage.setItem(LOCAL_ANSWERS, JSON.stringify(answers));
}

const LOCAL_RESULTS = 'CURRENT_RESULTS';

const getLocalResults = (): IResult => {
    const results = localStorage.getItem(LOCAL_RESULTS);
    if (results) {
        return JSON.parse(results) as IResult;
    }
    return defaultResults;
}

const setLocalResults = (results: IResult) => {
    localStorage.setItem(LOCAL_RESULTS, JSON.stringify(results));
}


export const AnswersProvider = ({ children }: IProps) => {
    const [answers, setAnswers] = useState<IAnswers>(getLocalAnswers());
    const [result, setResult] = useState<IResult>(getLocalResults());

    useEffect(() => {
        setLocalAnswers(answers);
    }, [answers])

    useEffect(() => {
        setLocalResults(result);
    }, [result])

    const clearCache = () => {
        localStorage.removeItem(LOCAL_RESULTS);
        localStorage.removeItem(LOCAL_ANSWERS);
    }

    const initResults = () => {
        clearCache();
        setResult(getLocalResults())
    }

    const setAnswer = (field: keyof IAnswers, value: any): Promise<IAnswers> => {
        observer.shout("Set-Answer", { field, value });

        return new Promise((res) => {
            setAnswers((currentState) => {
                const newState = {
                    ...currentState,
                    [field]: value
                }
                res(newState);
                return newState;
            })
        })
    }

    const value = {
        answers,
        setAnswer,
        result,
        setResult,
        clearCache,
        initResults
    }
    return (
        <answersContext.Provider value={value}>
            {children}
        </answersContext.Provider>
    )
}

export const Counter = () => {
    const [count, setCount] = useState(0);

    useEffect(() => {
        setTimeout(() => {
            setCount(count + 1);
        }, 5000)
    }, [])

    return (
        <div>
            <h2>Count, {count}</h2>
            <button onClick={() => setCount(count + 1)}>Add</button>
        </div>
    )
}