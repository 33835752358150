import { Line } from "UIKit/Layouts/Line/Line"
import { CheckBoxAction } from "../CheckBox/CheckBox"
import { LinkBtn } from "../Btn/Btn"
import { useLang } from "Lang/useLang"
import { useContext } from "react"
import { answersContext } from "Context/answersContext"
import { api, routes } from "helpers/api"
import { MarkdownShow } from "../MarkdownShow/MarkdownShow"
import { useMarkdown } from "Hooks/useMarkdown"
import { domainContext } from "Context/domainContext"

export const Terms = () => {
    const { answers, setAnswer } = useContext(answersContext);
    const domain = useContext(domainContext);
    const privacy = domain?.privacy?.toUpperCase();

    const { $ } = useLang();
    const fetchPolicy = useMarkdown(`ABOUT_PRIVACY_${privacy}`);
    const fetchAgreement = useMarkdown(`ABOUT_USER_AGREEMENT_${privacy}`);

    const handleAgreement = () => {
        window.popup({ elem: <MarkdownShow fetch={fetchAgreement} /> })
    }

    const handlePolicy = () => {
        window.popup({ elem: <MarkdownShow fetch={fetchPolicy} /> })
    }

    const handleToggle = () => {
        setAnswer('terms', !answers.terms);
    }


    return (
        <div className="Terms">
            <Line>
                <CheckBoxAction item={{
                    checked: answers.terms,
                    title: 'ACTION_TERMS',
                    titleArgs: [
                        <LinkBtn onClick={handleAgreement}>{$('ACTION_TERMS_AGREEMENT')}</LinkBtn>,
                        <LinkBtn onClick={handlePolicy}>{$('ACTION_TERMS_POLICY')}</LinkBtn>,
                    ],
                    titleActions: true
                }} onChange={handleToggle} />
            </Line>
        </div>
    )
}