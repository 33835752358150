import { useLang } from "Lang/useLang"
import { api, routes } from "../helpers/api"

export const useMarkdown = (key: string) => {
    const { langIndex } = useLang();

    const fetch = async () => {
        return await api.get(routes.server + `/markdown/survey-new/${langIndex}/${key}`)
    }

    return fetch;
}