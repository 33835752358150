import { useLang } from "Lang/useLang"
import { Accordion, BtnBig, H1, IconEmailAt, OpenMail } from "UIKit";

import './Support.css';

export const Support = () => {
    const { $ } = useLang();

    const qaValue = $('Q_AND_A');

    if (!qaValue) return <></>

    const list = qaValue.split('--');

    const mapAll = () => {
        return list.map((batch: string) => {
            const splt = batch.split('A:');
            const q = splt[0].replace('Q:', '').trim();
            const a = splt[1].trim();

            return (
                <div key={batch}>
                    <Accordion
                        header={q}
                        content={a}
                    />
                </div>
            )
        })
    }

    return (
        <div className="Support">
            <H1>{$('HEADER_SUPPORT')}</H1>
            <div>
                <OpenMail to={$('PREFIX_SUPPORT_EMIL')}>
                    <BtnBig
                        title={$('DOCTOR_NOTE_EMAIL_TITLE')}
                        info={$('PREFIX_SUPPORT_EMIL')}
                        i={<IconEmailAt />}
                    />
                </OpenMail>
            </div>
            <div>
                {mapAll()}
            </div>
        </div>
    )
}