import './Inner.css';

interface IProps {
    children: Elem;
    addClass?: string;
}
export const Inner = ({ children, addClass }: IProps) => {
    return (
        <div className={`Inner ${addClass || ''}`}>
            {children}
        </div>
    )
}

export const InnerSmall = (props: IProps) => {
    return <Inner {...props} addClass="small" />
}

export const InnerSide = (props: IProps) => {
    return <Inner {...props} addClass="side" />
}
