import { answersOptions } from "Context/answersContext";
import { IFlowItem } from "Flow/types/flow.types";
import { answersMaps } from "Questions/helpers/maps";
import { ElemType } from "Questions/types/question.types";

export const Step11: IFlowItem[] = [
    {
        field: 'eczemaSigns',
        titleArgs: ['infantFirstName'],
        flex: 'split',
        elem: answersMaps.optionsToElem(answersOptions.yesNo, ElemType.Radio)
    },
    {
        field: 'eczemaSignsDiagnose',
        titleArgs: ['infantFirstName'],
        flex: 'split',
        cond: (answers) => {
            return answers.eczemaSigns === 'yes'
        },
        elem: answersMaps.optionsToElem(answersOptions.yesNo, ElemType.Radio)
    },
    {
        field: 'eczemaSystematicAntibiotics',
        titleArgs: ['infantFirstName'],
        flex: 'split',
        elem: answersMaps.optionsToElem(answersOptions.yesNo, ElemType.Radio)
    },
    {
        field: 'eczemaTopicalAntibiotics',
        titleArgs: ['infantFirstName'],
        flex: 'split',
        elem: answersMaps.optionsToElem(answersOptions.yesNo, ElemType.Radio)
    }
]       