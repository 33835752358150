
import { Center, Questions, Rows, Step } from 'UIKit';
import './App.css';
import { HomeLayout } from 'Screens/HomeLayout/HomeLayout';
import { useContext, useEffect, useState } from 'react';
import { setIsComplete, stepsContext } from 'Questions/context/stepsContext';
import { useNavigate, useParams } from 'react-router-dom';
import { answersContext } from 'Context/answersContext';
import { useGTM } from 'Questions/hooks/useGTM';


function App() {
  const { domain, subDomain = '' } = useParams();
  const [isInit, setIsInit] = useState(false);
  const { handleNext, next, header, per, actions, initSteps } = useContext(stepsContext);
  const { initResults } = useContext(answersContext);
  const navigate = useNavigate();
  const isComplete = typeof next === 'string';
  useGTM();

  const getPath = () => {
    return domain + (subDomain ? '/' + subDomain : '');
  }
  useEffect(() => {
    initSteps();
    initResults();
    setIsInit(true);
  }, []);

  useEffect(() => {
    if (isInit && isComplete) {
      setIsComplete(true);
      navigate(`/${getPath()}${next}`)
    }
    window.scrollTo(0, 0);
  }, [isInit, next])


  return (
    <div className="App">
      <HomeLayout>
        <Center>
          <Rows>
            {!isComplete && header && per && (
              <Step header={header} per={per}>
                <Questions flowItems={next} onNext={handleNext} Actions={actions} />
              </Step>
            )}
          </Rows>
        </Center>
      </HomeLayout>

    </div>
  );
}

export default App;
