import { answersOptions } from "Context/answersContext";
import { IFlowItem } from "Flow/types/flow.types";
import { answersMaps } from "Questions/helpers/maps";
import { ElemType } from "Questions/types/question.types";

export const Step7: IFlowItem[] = [
    {
        field: 'isSiblings',
        flex: 'split',
        elem: answersMaps.optionsToElem(answersOptions.yesNo, ElemType.Radio)
    },
    {
        field: 'siblingsCount',
        cond: (answers) => {
            return answers.isSiblings === 'yes'
        },
        onChange: (answers, setAnswers) => {
            setAnswers('siblingsConditionsCount', '');
        },
        elem: answersMaps.toInput(1, 12, 'number')
    },
    {
        field: 'siblingsConditions',
        cond: (answers) => {
            return answers.isSiblings === 'yes'
        },
        onChange: (answers, setAnswers) => {
            if(answers.siblingsConditions.includes('none')) {
                setAnswers('siblingsConditionsCount', '');
            }
        },
        flex: "split",
        elem: answersMaps.optionsToElem(answersOptions.conditions, ElemType.Checkbox)
    },
    {
        field: 'siblingsConditionsCount',
        cond: (answers) => {
            return answers.isSiblings === 'yes' && answers.siblingsConditions.length > 0 && !answers.siblingsConditions.includes('none')
        },
        elem: answersMaps.toInput(1, (answers) => +answers.siblingsCount, 'number')    
    },
    {
        field: 'isSmoking',
        titleCond(answers) {
            if(answers.infantAge === 'no_born') {
                return 'TITLE_SMOKING_PRE'
            }
            return 'TITLE_SMOKING'
        },
        titleArgs: ['infantFirstName'],
        flex: 'split',
        elem: answersMaps.optionsToElem(answersOptions.yesNo, ElemType.Radio)
    },
]