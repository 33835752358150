import {
  Between,
  IconArrowDown,
  Text,
  Line,
} from "UIKit";
import "./Dropdown.css";
import { useEffect, useRef, useState } from "react";

interface IProps {
  list: ListItem[];
  selected?: string | number;
  onChange: (id: string | number) => void;
  isResponsive?: boolean;
  label?: string;
  error?: boolean;
  hasBorder?: boolean;
  isSpread?: boolean;
}

type ListItem = {
  id: string | number;
  value: string;
  icon?: Elem
}

export const Dropdown = ({ list, selected, onChange, isResponsive, label, error, hasBorder, isSpread }: IProps) => {
  const [isDisp, setIsDisp] = useState(false);
  const wrapRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    document.body.addEventListener("click", handleBodyClick);

    return () => {
      document.body.removeEventListener("click", handleBodyClick);
    };
  }, []);

  const handleBodyClick = (e: any) => {
    if(!wrapRef.current || !e.target) { return }

    if(!wrapRef.current.contains(e.target)) {
      setIsDisp(false);
    }
  };

  const handleToggle = (e: any) => {
    setIsDisp(!isDisp);
  };

  const renderHeader = (): Elem => {
    if (selected !== undefined) {
      const item = list.find((i) => i.id === selected);

      if (item) {
        if (item.icon) {
          return (
            <Line>
              <span>{item.icon}</span>
              <Text>{item.value}</Text>
            </Line>
          );
        }
        return <Text>{item.value}</Text>;
      }
    }
    return label || "...";
  };

  const handleSelect = (item: ListItem) => {
    onChange(item.id);
    setIsDisp(false);
  };

  const getMedia = () => {
    if (isResponsive) {
      if (list.find(i => i.icon)) {
        return "dropdown-icon";
      }
      return "dropdown";
    }
    return ''
  }

  const renderList = () => {
    return list.map((item) => {
      return (
        <div
          className={item.id === selected ? "selected" : ""}
          key={item.id}
          onClick={() => handleSelect(item)}
        >
          <Line>
            <span>{item.icon}</span>
            <Text>{item.value}</Text>
          </Line>
        </div>
      );
    });
  };

  return (
    <div
      className="Dropdown"
      data-media={getMedia()}
      data-spread={isSpread}
      data-error={error}
      data-has-border={hasBorder}
      ref={wrapRef}
    >
      <div className="header" onClick={handleToggle}>
        <Between>
          <span>{renderHeader()}</span>

          <IconArrowDown />
        </Between>
      </div>
      {isDisp && <div className="list">{renderList()}</div>}
    </div>
  );
};
