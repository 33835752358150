import { useEffect, useRef, useState } from 'react';
import './DropMenu.css';

interface IProps {
    children: Elem;
    menu: Elem;
    onOpen?: () => void;
}
export const DropMenu = (props: IProps) => {
    const { children, menu, onOpen } = props;
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const wrapRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        document.body.addEventListener("click", handleBodyClick);
    
        return () => {
          document.body.removeEventListener("click", handleBodyClick);
        };
      }, []);
    
      const handleBodyClick = (e: any) => {
        if(!wrapRef.current || !e.target) { return; }

        if(!wrapRef.current.contains(e.target)) {
            setIsOpen(false);
        }
        
      };

    const handleToggle = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        setIsOpen(!isOpen);
    }

    useEffect(() => {
        if(isOpen && onOpen) {
            onOpen();
        }
    }, [isOpen])

    return (
        <div className="DropMenu" ref={wrapRef}>
            <div className='header' onClick={handleToggle}>
                {children}
            </div>
            {isOpen && (
                <div className='menu'>
                    {menu}
                </div>
            )}
        </div>
    )
}