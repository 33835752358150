import { answersOptions } from "Context/answersContext";
import { IFlowItem } from "Flow/types/flow.types";
import { answersMaps } from "Questions/helpers/maps";
import { ElemType } from "Questions/types/question.types";

export const Step5: IFlowItem[] = [
    {
        field: 'motherConditions',
        titleCond: (answers) => {
            if(answers.iam === 'bio_mother') {
                return 'TITLE_MOTHERCONDITIONS_IAM';
            }
            return 'TITLE_MOTHERCONDITIONS';
        },
        flex: 'split',
        elem: answersMaps.optionsToElem(answersOptions.conditions, ElemType.Checkbox)
    },
    {
        field: 'conditionsDuringPregnant',
        titleCond: (answers) => {
            if(answers.iam === 'bio_mother') {
                return 'TITLE_CONDITIONSDURINGPREGNANT_IAM';
            }
            return 'TITLE_CONDITIONSDURINGPREGNANT';
        },
        flex: 'split',
        cond: (answers) => {
            return answers.motherConditions.length > 0 && !answers.motherConditions.includes('none');
        },
        elem: answersMaps.optionsToElem(answersOptions.yesNo, ElemType.Radio)
    },
    {
        field: 'antibiotics',
        titleCond: (answers) => {
            if(answers.iam === 'bio_mother') {
                return 'TITLE_ANTIBIOTICS_IAM';
            }
            return 'TITLE_ANTIBIOTICS';
        },
        flex: 'split',
        elem: answersMaps.optionsToElem(answersOptions.prescription, ElemType.Radio)
    }
]