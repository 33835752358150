
interface IObserver {
    list: Record<string, any>[];
    listen: (name: TObserverName, evt: (payload: any) => void) => void;
    has: (name: string) => boolean;
    shout: (name: TObserverName, payload: any) => void;
}

type TObserverName = 'Set-Answer' | 'Save-Step' | 'Survey-Complete' | 'region_picker_open' | 'region_picker_select';

export const observer: IObserver = {
    list: [],
    listen: (name, evt) => {
        if(observer.has(name)) {
            observer.list = observer.list.filter(i => i.name !== name);
        }

        observer.list.push({
            name,
            evt
        })
    },
    has: (name) => {
        return !!observer.list.find(i => i.name === name);
    },
    shout: (name, payload) => {
        observer.list.forEach(i => {
            if(i.name === name) {
                i.evt(payload)
            }
        })
    }
}