import { getSubDomain } from "Utils/domains";
import { api, routes } from "helpers/api";
import { createContext, useEffect, useState } from "react";

export const domainContext = createContext<IDomainContext | null>(null);

const Provider = domainContext.Provider;

export interface IDomainContext {
    dateFormat?: TDateFormat;
    highRisk?: string;
    lowRisk?: string;
    scheduleLink?: string;
    id?: string;
    lang?: string;
    privacy?: 'rest' | 'europe' | 'mexico' | 'australia';
    short?: string;
    weight?: 'pounds' | 'kg';
    isLoading: boolean;
    externalSchedule?: boolean;
    returnToLink?: string;
    nextToLink?: string;
    countryId?: string;
    selectedLangs?: string[];
    defaultLang?: string;
    isSuperDietitianDomain?: boolean;
}

export type TDateFormat = 'MM/DD/YYYY' | 'DD/MM/YYYY';

interface IProps {
    children: Elem
}

export const DomainProvider = ({ children }: IProps) => {
    const [data, setData] = useState<IDomainContext | null>(null);

    const { domain, subDomain } = getSubDomain();

    useEffect(() => {
        if (domain) {
            api.get(`${routes.domains}/explore/${domain}/${subDomain || ''}`)
                .then(resp => {
                    setData(resp)
                });
        } else {
            api.get(routes.server + "/translation/countries/env").then((resp) => {
                if(resp.length) {
                    window.location.href = '/' + resp[0].url;
                }
            });
        }

    }, [])

    const value = {
        isLoading: data === null,
        ...data
    }
    return (
        <Provider value={value}>
            {children}
        </Provider>
    )
}