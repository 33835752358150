
export const convertWeight = (value: number, unit: 'lb' | 'kg') => {
    if (unit === "lb") {
        // Convert pounds to kilograms (1 pound = 0.45359237 kilograms)
        return +value * 0.45359237;
    } else if (unit === "kg") {
        // Convert kilograms to pounds (1 kilogram = 2.20462262 pounds)
        return +value * 2.20462262;
    } else {
        return +value;
    }
}

export const convertPoundToKg = (value: number) => {
    return parseInt(convertWeight(value, 'lb').toString())
}

export const convertKgToPounds = (value: number) => {
    return parseInt(convertWeight(value, 'kg').toString())
}