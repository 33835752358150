import { domainContext } from "Context/domainContext";
import { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

export const SurveyDone = () => {
    const { domain, subDomain = '' } = useParams();
    const data = useContext(domainContext);
    const navigate = useNavigate();
    const getPath = () => {
        return domain + (subDomain ? '/' + subDomain : '');
      }

    useEffect(() => {
        if (!data?.isLoading) {
            if (data?.isSuperDietitianDomain) {
                navigate(`/${getPath()}/complete`)
            }else {
                navigate(`/${getPath()}/results`)
            }
        }
    }, [data?.isLoading])
    console.log(data);

    return (
        <div>
            <h1>sds</h1>
        </div>
    )
}