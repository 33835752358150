import { useInput } from "Hooks/useInput"
import { useLang } from "Lang/useLang";
import { useContext, useEffect } from "react";
import { Input } from "UIKit"

import './Datepicker.css';
import { domainContext } from "Context/domainContext";

interface IProps {
    value: Date;
    max?: Date,
    min?: Date,
    onChange: (value: Date) => void,
    error?:boolean;
}


export const Datepicker = (props: IProps) => {
    const domain = useContext(domainContext);
    const { value = new Date(), max, min, onChange, error } = props;    
    const DD = useInput(value.getDate(), 'DD');
    const MM = useInput(value.getMonth() + 1, 'MM');
    const YYYY = useInput(value.getFullYear(), 'YYYY');
    const { $ } = useLang();
    
    useEffect(() => {
        const date = new Date(+YYYY.value, +MM.value - 1, +DD.value);

        if (max && max < date) {
            setDate(max);
            return;
        }
        if (min && min > date) {
            setDate(min);
            return;
        }
        onChange(date);
    }, [DD.value, MM.value, YYYY.value])

    const setDate = (d: Date) => {
        YYYY.onChange(d.getFullYear().toString());
        MM.onChange((d.getMonth() + 1).toString());
        DD.onChange(d.getDate().toString());
        onChange(d);
    }

    const getFormat = () => {
        const arr = [
            <div key="MM">
                <Input {...MM} type="number" max={12} min={1} />
                <h4>{$('FIELD_KEY_MM')}</h4>
            </div>,
            <div key="DD">
                <Input {...DD} type="number" max={31} min={1} />
                <h4>{$('FIELD_KEY_DD')}</h4>
            </div>
        ]
        if (domain?.dateFormat === 'DD/MM/YYYY') {
            arr.reverse()
        }
        return arr;

    }

    return (
        <div className="Datepicker" data-error={error}>
            {getFormat()}
            <div>
                <Input {...YYYY} type="number" />
                <h4>{$('FIELD_KEY_YYYY')}</h4>
            </div>
        </div>
    )
}