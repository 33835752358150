import { answersOptions } from "Context/answersContext";
import { IFlowItem } from "Flow/types/flow.types";
import { answersMaps } from "Questions/helpers/maps";
import { ElemType } from "Questions/types/question.types";
import { addDays, addMonths } from "helpers/date";

export const Step4: IFlowItem[] = [
    {
        field: 'infantDueDateOfBirth',
        titleCond() {
            return 'TITLE_INFANT_EXPECTED_DOB'
        },
        titleArgs: ['infantFirstName'],
        validate: ['date'],
        validateArgs: {
            minDate: addDays(new Date(), 0),
            maxDate: addMonths(new Date(), 9)
        },
        elem: answersMaps.toDate()
    },
    {
        field: 'genderKnown',
        flex: 'triple',
        elem: answersMaps.optionsToElem(answersOptions.genderKnown, ElemType.Radio)
    },
    {
        field: 'childEnvironment',
        elem: answersMaps.optionsToElem(answersOptions.environment, ElemType.Radio)
    }
]