
import { Center, Text, Rows, Step, Padding, H3, Btn, RowsFull, SepLine, IconHighRisk, IconLowRisk, IconText, CenterAlign, H2, BtnCancel } from 'UIKit';
import { HomeLayout } from 'Screens/HomeLayout/HomeLayout';
import { Modal } from 'Shared/Modal/Modal';
import { useLang } from 'Lang/useLang';
import { useContext } from 'react';
import { getIsComplete, stepsContext } from 'Questions/context/stepsContext';
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';
import { IAnswers, answersContext } from 'Context/answersContext';
import { domainContext } from 'Context/domainContext';


export const Schedule = () => {
    const { handleBack } = useContext(stepsContext);
    const { result } = useContext(answersContext);
    const domain = useContext(domainContext);

    const { domain: domainParam } = useParams();
    const isMobile = window.innerWidth < 600;

    const { $ } = useLang();

    const isHighRisk = result.ml.AD === 'high' || result.ml.FA === 'high';

    const handleSchedule = () => {
        window.popup({
            elem: (
                <div style={{ width: isMobile ? '100%' : '800px', height: '650px' }}>
                    <iframe src={domain?.scheduleLink} style={{ width: '100%', height: '650px', border: 'none' }} ></iframe>
                </div>
            )
        });
    }

    const getIcon = () => {
        if (isHighRisk) {
            return (
                <IconText
                    text={$('ICON_HIGH_RISK')}
                    icon={<IconHighRisk />}
                />
            )
        }
        return (
            <IconText
                text={$('ICON_LOW_RISK')}
                icon={<IconLowRisk />}
            />
        )
    }

    const answers = result.answers as IAnswers || {};

    const keys = isHighRisk ? (
        {
            headline: answers.infantAge === 'no_born' ? 'SCHEDULE_INFO_AT_RISK_NO_BORN' : 'SCHEDULE_INFO_AT_RISK',
            title: answers.infantAge === 'no_born' ? 'SCHEDULE_HIGH_TITLE_NO_BORN' : 'SCHEDULE_HIGH_TITLE',
            info: 'SCHEDULE_HIGH_INFO'
        }
    ) :
        (
            {
                headline: 'SCHEDULE_INFO_LOW_RISK',
                title: answers.infantAge === 'no_born' ? 'SCHEDULE_LOW_TITLE_NO_BORN' : 'SCHEDULE_LOW_TITLE',
                info: 'SCHEDULE_LOW_INFO'
            }
        )

    if (!getIsComplete()) {
        return <Navigate to={`/${domainParam}`} />
    }

    return (
        <div className={`Schedule`} data-risk={isHighRisk ? 'high' : 'low'}>
            <HomeLayout>
                <Center>
                    <Rows>
                        <Step>
                            <Padding>
                                <RowsFull>
                                    <CenterAlign>
                                        {getIcon()}
                                    </CenterAlign>
                                    <H2 bold mark center>{answers.infantFirstName}</H2>
                                    <Text center>{$(keys.headline)}</Text>
                                    <SepLine />
                                    <Text bold center>{$(keys.title, answers.infantFirstName)}</Text>
                                    <ul>
                                        {$(keys.info).split('- ').filter((t: string) => !!t.trim()).map((t: string) => (
                                            <li key={t}>
                                                <Text left >{t}</Text>
                                            </li>
                                        ))}
                                    </ul>
                                    {domain?.externalSchedule ? (
                                        <Link to={domain?.scheduleLink || ''} className='contents'>
                                            <Btn onClick={() => { }}>{$('SCHEDULE_BTN_NEXT')}</Btn>
                                        </Link>
                                    ) : (
                                        <Btn onClick={handleSchedule}>{$('SCHEDULE_BTN_NEXT')}</Btn>
                                    )}

                                    {/* <BtnCancel onClick={handleCancel}>{$('SCHEDULE_BTN_LEARN')}</BtnCancel> */}
                                </RowsFull>
                            </Padding>
                        </Step>
                    </Rows>
                </Center>
            </HomeLayout>

        </div >
    );
}