import { ElemItem, ElemType, InputType, NumFunc } from "../types/question.types";

export const answersMaps = {
    optionsToElem<T extends string>(options: readonly T[], type: ElemType) {
        return options.map(key => (
            {
                type,
                label: `FIELD_OPTION_${key.toUpperCase()}`,
                value: key
            }
        ))
    },

    optionsToDropdown<T extends string>(options: readonly T[], label?: string, value?: string) {
        return [{
            type: ElemType.Dropdown,
            label,
            value,
            options: options.map(key => (
                {
                    id: key,
                    value: `FIELD_OPTION_${key.toUpperCase()}`
                }
            ))
        }]
    },

    toInput: (min?: NumFunc, max?: NumFunc, inputType?: InputType) => {
        return [
            {
                min,
                max,
                inputType,
                type: ElemType.Input
            }
        ]
    },

    toInputLetters: (): ElemItem[] => {
        return [
            {
                maxLength: 15,
                allow: 'letters',
                type: ElemType.Input
            }
        ]
    },

    toPhone: () => {
        return [
            {
                type: ElemType.Phone
            }
        ]
    },

    toEmail: ():  ElemItem[] => {
        return [
            {
                inputType: 'email',
                type: ElemType.Input
            }
        ]
    },

    toDate: (min? : Date, max?: Date) => {
        return [
            {
                minDate: min,
                maxDate: max,
                type: ElemType.Date
            }
        ]
    },
}