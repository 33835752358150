import { SetStateAction, useEffect, useRef } from "react";
import { useState } from "react";
import { createContext } from "react";
import { useContext } from "react";
import { createPortal } from "react-dom";
import { H3, Center, Text, SepLine, Line, Padding, Btn, SpreadEven, PaddingTop, Rows, IconClose, Touchable, End, RowsShort } from "UIKit";

import './Modal.css';
import { Icon } from "UIKit/Elements/Icon/Icon";
import { useLang } from "Lang/useLang";

export const modalContext = createContext<IModalContext>({
    isShow: false,
    setIsShow: () => { }
});

const Provider = modalContext.Provider;

interface IModalProvider {
    children: Elem;
}

interface IModalContext {
    isShow: Boolean,
    setIsShow: (flag: SetStateAction<boolean>) => void
}
export const ModalProvider = ({ children }: IModalProvider) => {
    const [isShow, setIsShow] = useState(false);

    const value = {
        isShow,
        setIsShow
    }
    return (
        <Provider value={value}>
            {children}
        </Provider>
    )
}

export const useModal = () => {
    const { isShow, setIsShow } = useContext(modalContext);

    return {
        isShow,
        setIsShow
    }
}

export const Modal = () => {
    const [props, setProps] = useState<IMessage>();
    const { isShow, setIsShow } = useModal();

    useEffect(() => {
        window.popup = (p: IMessage) => {
            return new Promise((res) => {
                setIsShow(true);
                setProps({
                    ...p, onRes: (resp) => {
                        setIsShow(false);
                        res(resp)
                    }
                });
            })
        }

        window.conf = (msg: string, title: string) => window.popup({ msg, title, isConf: true })

        window.toast = (msg, type = "ok", time = 5000) => window.popup({ msg, type, time, toast: true });
        window.toastError = (msg, type = "error", time = 5000) => window.popup({ msg, type, time, toast: true });

        window.popupHide = () => setIsShow(false)
    }, [])

    if (!isShow || !props?.onRes) {
        return null
    }

    return createPortal(props?.toast ? <Toast {...props} /> : <Popup {...props} />, document?.getElementById('modal')!);
}

export const Popup = (props: IMessage) => {

    const wrapRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        setTimeout(() => {
            wrapRef?.current?.classList.add('shown');
        }, 100);
    }, [])

    const handleCloseByScreen = (e: any) => {
        if (e?.target.classList.contains('screen')) {
            props.onRes(false)
        }
    }

    return (
        <div ref={wrapRef} className="Popup" data-media="modal-popup">
            <div className="screen" onClick={handleCloseByScreen}>
                <div className="content">
                    <RowsShort>
                        <End>
                            <Touchable onClick={() => props.onRes(false)}>
                                <Icon i={IconClose} />
                            </Touchable>
                        </End>
                        {props.msg ? <Message {...props} /> : props.elem}
                    </RowsShort>
                </div>
            </div>
        </div>
    )
}

export const Toast = ({ msg, type, time, onRes }: IMessage) => {

    const wrapRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        setTimeout(() => {
            wrapRef?.current?.classList.add('shown');
        }, 200);

        setTimeout(() => {
            wrapRef?.current?.classList.remove('shown');
            setTimeout(() => {
                onRes(true);
            }, 500);
        }, time)
    }, [])


    return (
        <div ref={wrapRef} className="Toast" data-type={type} data-media="modal-toast">
            <div className="content">
                <Line>
                    {msg}
                </Line>
            </div>
        </div>
    )
}

interface IMessage {
    onRes: (resp: boolean) => void;
    msg?: string;
    isConf?: boolean;
    toast?: boolean;
    title?: string;
    type?: TMessageType;
    time?: number;
    elem?: Elem;
}

type TMessageType = 'ok' | 'error';

const Message = ({ onRes, msg, isConf, title = "Message" }: IMessage) => {
    const { $ } = useLang();

    return (
        <div className="msg" data-media="popup-msg">
            <PaddingTop>
                <Center>
                    <H3>{title}</H3>
                </Center>
            </PaddingTop>
            <Padding>
                <Center>
                    <Text>{msg}</Text>
                </Center>
            </Padding>
            <SepLine />
            <Padding>
                <SpreadEven>
                    {isConf && <Btn type="invert" onClick={() => onRes(false)}>{$('MODAL_NO')}</Btn>}
                    <Btn onClick={() => onRes(true)}>{isConf ? $('MODAL_YES') : $('MODAL_OK')}</Btn>
                </SpreadEven>
            </Padding>
        </div>
    )
}
