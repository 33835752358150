export const getSubDomain = () => {
    const { pathname } = window.location;
    const [_, domain, subDomain = ''] = pathname.split('/');

    return {
        domain,
        subDomain: [
            'complete', 'followup',
            'results', 'schedule', 'survey_done'
        ].includes(subDomain) ? null : subDomain
    }
}