
import { Center, Text, Rows, Step, Padding, Btn, RowsFull, SepLine, IconComplete, H1, Input, BtnCancel, Wrap, CenterAlign } from 'UIKit';
import { HomeLayout } from 'Screens/HomeLayout/HomeLayout';
import { useLang } from 'Lang/useLang';
import { Link, Navigate } from 'react-router-dom';
import { useResults } from 'Hooks/useResults';
import { ResultsLoader } from './components/ResultsLoader';


export const Results = () => {
    const { $ } = useLang();
    const {
        isComplete,
        handleNext,
        returnToLink,
        error,
        isLoading,
        domain,
        Code
    } = useResults();

    if (!isComplete) {
        return <Navigate to={`/${domain}`} />
    }

    return (
        <div className="App">
            <HomeLayout>
                <Center>
                    <Rows>
                        <Step>
                            <Wrap height={450}>
                                {
                                    !isLoading ? (
                                        <>
                                            <CenterAlign>
                                                <IconComplete />
                                            </CenterAlign>
                                            <H1 bold mark center>{$('RESULTS_HEADLINE')}</H1>
                                            <Text mark center>{$('RESULTS_CONTENT')}</Text>
                                            <Padding>
                                                <Input {...Code} isCopy focus />
                                            </Padding>
                                        </>
                                    ) :
                                        (
                                            <ResultsLoader error={error} />
                                        )
                                }
                            </Wrap>
                            <SepLine />
                            <Padding>
                                <RowsFull>

                                    <Btn onClick={handleNext}>{$('RESULTS_BTN_NEXT')}</Btn>
                                    <Link to={returnToLink} className='contents'>
                                        <BtnCancel onClick={() => { }}>{$('RESULTS_BTN_BACK')}</BtnCancel>
                                    </Link>
                                </RowsFull>
                            </Padding>
                        </Step>
                    </Rows>
                </Center>
            </HomeLayout>

        </div>
    );
}