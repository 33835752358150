import { answersContext } from "Context/answersContext";
import { IFlowItem, IFlowItemErr } from "Flow/types/flow.types";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import { useContext, useState } from "react";
import { domainContext } from "Context/domainContext";
import { useLang } from "Lang/useLang";

export const useValidator = (items: IFlowItem[]) => {
    const domain = useContext(domainContext);
    const { answers } = useContext(answersContext);
    const [isOn, setIsOn] = useState(false);
    const { country }  = useLang();

    const validateScan = () => {
        items.forEach(item => {
            const val = answers[item.field];

            item.error = 'none';
            item.isErrorOn = isOn;

            if (check('empty', val as string)) {
                item.error = 'empty';
            } else {
                (['email', 'phone', 'date'] as IFlowItemErr[]).forEach((flag) => {
                    if (item.validate?.includes(flag)) {
                        if (check(flag, val as string, item)) {
                            item.error = flag;
                        }
                    }
                })
            }


        });
    }

    const turnOnValidation = () => {
        setIsOn(true);
    }

    const clear = () => {
        setIsOn(false);
    }

    const getItems = () => {
        return items.filter(i => {
            if(i.cond) {
                return i.cond(answers, domain!, country);
            }
            return true;
        })
    }

    const isEmpty = () => !!getItems().find(i => {
        return i.error === 'empty';
    })
    const isValid = () => {
        turnOnValidation();
        const list = getItems();
        return !list.find(i => i.error !== 'none');
    };

    return {
        validateScan,
        isEmpty,
        isValid,
        clear
    }
}


const check = (key: IFlowItemErr, value: string | string[] | boolean | Date, item?: IFlowItem) => {
    const isArray = Array.isArray(value);

    switch (key.trim()) {
        case "empty": {
            if (value === '' || value === false || value === undefined || (isArray && !value.length)) {
                return true;
            }
            break;
        }
        case "email": {
            var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
            if (!(value as string).match(validRegex)) {
                return true;
            }
            break;
        }
        case "phone": {
            if (!isPossiblePhoneNumber(value as string)) {
                return true
            }
            break;
        }
        case "false": {
            if (!value) {
                return true;
            }
            break;
        }
        case "date" : {
            if(item && item.validateArgs) {
                const { minDate, maxDate } = item.validateArgs;
                if(minDate && (value as Date) < minDate) {
                    return true;
                }
                if(maxDate && (value as Date) > maxDate) {
                    return true;
                }
            }
        }
    }
}