import './Icon.css';

type TSize = 'large' | 'medium' | 'small';

export interface IConProps {
    onClick?: () => void;
    children?: Elem;
    i?: () => JSX.Element;
    theme?: string;
    size?: TSize
}

export const Icon = ({ children, size, i }: IConProps) => {
    return (
        <div className="Icon" data-size={size}>
            {i ? i() : children}
        </div>
    )
}

export const IconRound = ({ i, onClick }: IConProps) => {
    return (
        <div className="Icon" data-shape={'round'} onClick={onClick} data-clickable={onClick ? true : false}>
            {i && i()}
        </div>
    )
}

export const IconRoundBig = ({ i, theme }: IConProps) => {
    return (
        <div className="Icon big" data-shape={'round'} data-theme={theme}>
            {i && i()}
        </div>
    )
}