import React from "react";
import './Typography.css';


enum TAGS {
    H1 = 'h1',
    H2 = 'h2',
    H3 = 'h3',
    H4 = 'h4',
    TEXT = 'p',
    BTN = 'button'
}

interface IPropsBasic {
    children?: JSX.Element | JSX.Element[] | string;
    onClick?: (e: Event) => void;
    className?: string;
    disabled?: boolean;
}

const weights = ['light', 'regular', 'medium', 'bold'];
const align = ['left', 'center', 'right'];
const skin = ['natural', 'ok', 'error', 'gray', 'mark'];

interface IAttr extends IPropsBasic {
    ['data-weight']?: typeof weights[number];
    ['data-align']?: typeof align[number];
    ['data-skin']?: typeof skin[number];
    ['data-type']?: string;
    ['data-media']?: string | null;
    ['data-indent']?: boolean;
}

interface IProps extends IPropsBasic {
    tag?: TAGS;
    type?: string;
    media?: string | null;
    light?: boolean;
    regular?: boolean;
    medium?:boolean;
    bold?:boolean;
    natural?: boolean;
    ok?:boolean;
    error?:boolean;
    gray?:boolean;
    mark?:boolean;
    left?:boolean;
    right?:boolean;
    center?:boolean;
    indent?:boolean;
}

export const Typography = ({
    tag = TAGS.H1,
    children = 'N/A',
    onClick,
    className,
    type,
    disabled,
    media,
    indent,
    ...props
}: IProps) => {

    const attr: IAttr = { onClick, className, disabled };
    
    //set weights
    attr[`data-weight`] = Object.keys(props).find(i => weights.includes(i));

    //set size
    attr[`data-align`] = Object.keys(props).find(i => align.includes(i));

    //set skin
    attr[`data-skin`] = Object.keys(props).find(i => skin.includes(i));

    attr['data-type'] = type;
    attr['data-media'] = media;
    attr['data-indent'] = indent;

    return React.createElement(tag, attr, children);
}

export const H1 = (props: IProps) => <Typography {...props} tag={TAGS.H1} />
export const H2 = (props: IProps) => <Typography {...props} tag={TAGS.H2} />
export const H3 = (props: IProps) => <Typography {...props} tag={TAGS.H3} />
export const H4 = (props: IProps) => <Typography {...props} tag={TAGS.H4} />
export const SmallText = (props: IProps) => <Typography {...props} tag={TAGS.H4} />
export const Text = (props: IProps) => <Typography {...props} tag={TAGS.TEXT} />
export const Error = (props: IProps) => <Typography {...props} tag={TAGS.TEXT} error />
export const Button = (props: IProps) => <Typography {...props} tag={TAGS.BTN} />
