import ReactDOM from 'react-dom/client';
import { LangProvider } from 'Lang/useLang';
import { AnswersProvider } from 'Context/answersContext';
import { StepsProvider } from 'Questions/context/stepsContext';
import { Modal, ModalProvider } from 'Shared/Modal/Modal';
import { Routes } from 'Routes';
import { DomainProvider } from 'Context/domainContext';

import './index.css';
import './media.css';

if(window.location.href.includes('//www.')) {
  window.location.href = window.location.href.replace('www.', '');
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <DomainProvider>
    <ModalProvider>
      <LangProvider>
        <AnswersProvider>
          <StepsProvider>
            <Modal />
            <Routes />
          </StepsProvider>
        </AnswersProvider>
      </LangProvider>
    </ModalProvider>
  </DomainProvider>
);

